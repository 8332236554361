
import { getIcon } from '../contents/icons';

const icons = [
    {
        id: 'Autopsy',
        icon: getIcon.autopsy,
        label: 'Autopsy',
        type: 'pdf'},
    {
        id: 'Crime Scene',
        icon: getIcon.crime,
        label: 'Crime Scene',
        type: 'pdf'},
    {
        id: 'Detective Letter',
        icon: getIcon.solution,
        label: 'Detective Letter',
        type: 'pdf'}];

export default icons;
