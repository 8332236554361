
import React, { useState } from 'react';
import { getComponentTag } from '../../_shared/utils';
import './builder.css';

const Builder = ({ config }) => {
    const [current, setCurrent] = useState(null);
    const close = () => {
        setCurrent(null);
    };
    const iconClick = icon => {
        setCurrent(icon);
    };

    return (
        <>
            <div className="list desktop-icons-container">
                {config.map(icon => (
                    <div className="desktop-icon" key={icon.id}>
                        <img onClick={() => iconClick(icon)} src={icon.icon} alt={icon.label} />
                    </div>
                ))}
                <div>{current?.type && getComponentTag(current, close)}</div>
            </div>
        </>);
};

export default Builder;
