
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Desktop from './components/desktop/Desktop';
import Div100vh from 'react-div-100vh'; //fullscreen-height
import 'bootstrap/dist/css/bootstrap.min.css'; //bootstrap-added

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <Div100vh dontresize="true" className="vh-100 hak-desktop" >
          <Desktop />
      </Div100vh>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
