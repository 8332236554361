
import { XLg } from 'react-bootstrap-icons';
import './viewer.css';

export const Header = ({ icon, close }) => {
    const divStyle = {
        width: 'auto',
        float: 'right',
        paddingRight: '5px',
        cursor: 'pointer',
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: '10'};
    return (
        <div className='hak-modal-header'>
            <div style={{ width: '100%', float: 'left', textAlign: 'center' }} className='hak-modal-header-label-secondlevel'>{icon.label}</div>
            {close &&
                <div style={divStyle}>
                    <XLg onClick={close} />
                </div>}
        </div >);
};
