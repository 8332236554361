
import React, { useState, useRef } from 'react';
import { contents } from '../contents/content';
import { Header } from './_shared/ModalHeader';
import './_shared/viewer.css';
import Draggable from 'react-draggable';


const PDFViewer = ({ icon, close }) => {

  const [loading, setLoading] = useState(true);
  const imageLoaded = () => setLoading(false);

  const current = 0;

  const nodeRef = useRef(null);
  const maxZoom = 350;
  const minZoom = 100;
  const zoomRange = 20;
  const [imgWidth, setImgWidth] = useState(minZoom);
  const [fetching, setFetching] = useState(false);
  const zoom = zoomIn => {
    let widthPercent = imgWidth;
    widthPercent = zoomIn ? Math.max(widthPercent - zoomRange, minZoom) : Math.min(widthPercent + zoomRange, maxZoom);
    setImgWidth(widthPercent);
  };
  const download = (url, name, e) => {
    e.preventDefault();
    if (!fetching) {
      setFetching(true);
      fetch(url + '?crossorigin')
        .then(response => response.blob())
        .then(blob => {
          setFetching(false);
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobURL;
          a.style = 'display: none';
          a.download = name;
          document.body.appendChild(a);
          a.click();
        })
        .catch(()=> console.warn('Error downloading image.'));
    }
  };

  return (<Draggable handle='.hak-modal-header-label-secondlevel' bounds={'#folder-bounds'} nodeRef={nodeRef}>
    <div className='hak-modal-pdf' ref={nodeRef}>
      <Header icon={icon} close={close} />
      {loading && <div className={'center'}>Loading..</div>}
      {<div style={{ 'overflow': 'auto', 'maxHeight': '75vh' }}>
        <img
          style={{ width: imgWidth + '%', display: loading ? 'none' : 'block' }}
          onLoad={imageLoaded}
          src={contents[icon.id][current]}
          alt={'File not available!'}
        />
      </div>}
        <div className={'center'} style={{ 'maxHeight': '5vh' }} >
        <div className='d-flex justify-content-between p-1'>
            <div style={{borderBottom: '0px'}}>
              <button disabled={imgWidth >= maxZoom} className='btn btn-sm' onClick={() => zoom(false)}>
                <img style={{ width: '20px' }} src='https://cdn.huntakiller.com/huntakiller/s10/zoomin-icon.svg' alt='' />
              </button>
              <button disabled={imgWidth <= minZoom} className='btn btn-sm' onClick={() => zoom(true)}>
                <img style={{ width: '20px' }} src='https://cdn.huntakiller.com/huntakiller/s10/zoomout-icon.svg' alt='' />
              </button>
              <button className='btn btn-sm' onClick={e => download(contents[icon.id][current], icon.label, e)}>
                <img style={{ width: '17px' }} src='https://cdn.huntakiller.com/huntakiller/s10/download-icon.svg' alt='' />
              </button>
            </div>
          </div>
          </div>
    </div>
  </Draggable>
  );
};

export default PDFViewer;
