
import { addResponseMessage } from 'react-chat-widget';
// Pdf viewer to display documents
import PDFViewer from '../components/viewer/PDFViewer';
// user inputs and corresponding response are imported
import { chat } from './config';

// access to saved messages
const homicedeMessages = 'homicide_at_the_heist_saved_messages';
// response for user input is handled
export const qna = question => {
    let tempResponse;
    // detective letter is invisible by default
    let isCorrectAnswer = false;
    for (const objKey in chat.inputs) {
        if (chat.inputs.hasOwnProperty(objKey)) {
            const tempObjKeyValue = chat.inputs[objKey].split(', ');
                tempObjKeyValue?.forEach(element => {
                    if (element.toLowerCase() === question.toLowerCase()) {
                        tempResponse = chat.outputs[String(objKey)];
                        // detective letter visible after correct answer
                        isCorrectAnswer = true;
                    }
                });
        }
    }
    if (!isCorrectAnswer) {
        // response for user inputs other than main suspects
        tempResponse = chat.outputs['26'];
    }
    return tempResponse;
};
// delaying response
export const addDelayedResponseMessage = (message, index) => {
    // 1.5 seconds delay per message
    const time = 1500;
    sleep(time * index).then(() => {
        addResponseMessage(message);
    });
};
// delaying detective letter popup in chat.
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
// updating the latest token to get the response of last message.
export const replaceTokens = message => message.replace('{{Correct}}', '').replace('{{Incorrect}}', '').replace('{', '').replace('}', '');
// pdf viewer added.
export const getComponentTag = (icon, close) => {
    const components = {
        pdf : PDFViewer};
    const TagName = components[icon.type];
    return <TagName icon={icon} close={close} />;
};
// get the message from local storage.
export const getSavedMessages = () => JSON.parse(localStorage.getItem(homicedeMessages) || '[]');
// messages are stored in local storage in order to continue where user left.
export const saveMessages = (messages = []) => {
    localStorage.setItem(homicedeMessages, JSON.stringify(messages));
};
