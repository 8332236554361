
export const getIcon = {
    aboutHeader: 'https://cdn.huntakiller.com/homicide-at-the-heist/about_header.svg',
    aboutIcons: 'https://cdn.huntakiller.com/homicide-at-the-heist/about_icons.svg',
    autopsy: 'https://cdn.huntakiller.com/homicide-at-the-heist/autopsy_pdf_icon.svg',
    autopsyPdf: 'https://cdn.huntakiller.com/homicide-at-the-heist/autopsy.jpg',
    barFood: 'https://cdn.huntakiller.com/homicide-at-the-heist/bar_food_icons.svg',
    barFoodHeader: 'https://cdn.huntakiller.com/homicide-at-the-heist/bar_food_menu_header.svg',
    nickyPhoto: 'https://cdn.huntakiller.com/homicide-at-the-heist/barcade_about_photo_nicky.jpg',
    vincentPhoto: 'https://cdn.huntakiller.com/homicide-at-the-heist/barcade_about_photo_vincent.jpg',
    menuIcon: 'https://cdn.huntakiller.com/homicide-at-the-heist/barcade_hamburger_menu.svg',
    banner: 'https://cdn.huntakiller.com/homicide-at-the-heist/barcade_header.jpg',
    barcadeLogo: 'https://cdn.huntakiller.com/homicide-at-the-heist/barcade_logo.svg',
    searchBar: 'https://cdn.huntakiller.com/homicide-at-the-heist/barcade_search_bar.svg',
    contactHeader: 'https://cdn.huntakiller.com/homicide-at-the-heist/contact_header.svg',
    contact: 'https://cdn.huntakiller.com/homicide-at-the-heist/contact_icons.svg',
    crime: 'https://cdn.huntakiller.com/homicide-at-the-heist/crime_scene_report_pdf_icon.svg',
    crimePdf: 'https://cdn.huntakiller.com/homicide-at-the-heist/crime_scene_report.jpg',
    drink: 'https://cdn.huntakiller.com/homicide-at-the-heist/drink_icons.svg',
    drinkHeader: 'https://cdn.huntakiller.com/homicide-at-the-heist/drink_menu_header.svg',
    gamesHeader: 'https://cdn.huntakiller.com/homicide-at-the-heist/games_header.svg',
    games: 'https://cdn.huntakiller.com/homicide-at-the-heist/games_icons.svg',
    hoursHeader: 'https://cdn.huntakiller.com/homicide-at-the-heist/hours_header.svg',
    hours: 'https://cdn.huntakiller.com/homicide-at-the-heist/hours_icons.svg',
    locationHeader: 'https://cdn.huntakiller.com/homicide-at-the-heist/location_header.svg',
    location: 'https://cdn.huntakiller.com/homicide-at-the-heist/location_icons.svg',
    closeIcon: 'https://cdn.huntakiller.com/homicide-at-the-heist/navbar_close_button.svg',
    solution: 'https://cdn.huntakiller.com/homicide-at-the-heist/solution_letter_icon.svg',
    solutionPdf: 'https://cdn.huntakiller.com/homicide-at-the-heist/solution_letter.jpg'};
